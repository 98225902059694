html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sign {
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main {
  margin-top: auto;
  margin-bottom: auto;
}

div {
  display: block;
}

.background {
  width: 100%;
  height: 100vh;
  padding: 0 0 0 0;
  margin: auto auto;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: 45px;
  width: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sign {
  width: 100%;
  font-size: 14px;
  font-family: MicrosoftYaHei;
}

.logo {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  width: 272px;
  display: flex;
  justify-content: space-around;
  line-height: 25px;
}
.span1 {
  font-size: 19px;
  font-weight: bold;
  color: rgba(9, 9, 9, 1);
}
.span2 {
  font-size: 19px;
}

.sign-in-container {
  width: 376px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 9px 0px rgba(37, 43, 147, 0.25);
  border-radius: 9px;
  margin: 34px auto auto auto;
  padding: 19px 52px;
  color: rgba(145, 145, 145, 1);
}

.input-prepend {
  width: 272px;
  height: 33px;
  margin: 20px auto 0 auto;
}

.input-prepend input {
  display: block;
  outline: none;
  border: 0;
  padding-left: 15px;
  line-height: 33px;
  text-align: start;
  width: 100%;
  background: rgba(244, 244, 244, 1);
}

.auto-login-btn {
  display: flex;
  text-align: start;
  line-height: 20px;
  font-size: 14px;
  margin: 15px auto 11px 0;
}

.session-auto-login {
  display: inline-block;
  width: 14px;
  margin: auto 0;
  height: 12px;
  background: rgba(29, 76, 181, 1);
  border-radius: 2px;
}

.sign-in-msg {
  font-size: 12px;
  margin-top: 16px;
}

label {
  font-size: 12px;
  padding: 2px 0;
  color: red;
}

.header {
  font-size: 24px;
  color: rgba(29, 76, 181, 1);
  display: flex;
  align-items: center;
  /* flex-direction: column; */
}
